<!-- 创建商品 -->
<template>
	<div class="bg-white px-3 goods_create" style="margin: -20px;margin-top: -1rem;margin-bottom: 0!important;">
		<router-link :to="{name:'jf_jfsc'}" style="position: absolute;top: 12px;left: 200px;">
			<el-button size="mini">回到积分商城</el-button>
		</router-link>


		<el-form ref="form" label-width="80px">
			
			<el-form-item label="类型" class="pt-3">
				<el-radio-group v-model="form.lx">
					<el-radio :label="0" border>实物</el-radio>
					<el-radio :label="1" border>优惠券</el-radio>
				</el-radio-group>
			</el-form-item>
			
			<el-form-item label="商品名称" >
				<el-input v-model="form.name" placeholder="请输入商品名称，不能超过60个字符" class="w-50"></el-input>
			</el-form-item>
			
			<el-form-item label="封面">
				<div>
					<span v-if="!form.cover" class="btn btn-light border mr-2" @click="chooseImage">
						<i class="el-icon-plus"></i>
					</span>
					<img v-else :src="form.cover" class="rounded" style="width: 45px;height: 45px;cursor: pointer;" @click="chooseImage" />
				</div>
			</el-form-item>
			
			<el-form-item label="商品描述">
				<el-input type="textarea" v-model="form.desc" placeholder="选填，商品卖点简述，例如：此款商品美观大方 性价比较高 不容错过" class="w-50"></el-input>
			</el-form-item>
			
			<el-form-item label="总库存">
				<el-input type="number" v-model="form.num" class="w-25">
					<template slot="append">件</template>
				</el-input>
			</el-form-item>
			
			<el-form-item label="兑换积分">
				<el-input type="number" v-model="form.jf" class="w-25">
					<template slot="append">积分</template>
				</el-input>
			</el-form-item>
			
			<el-form-item label="日兑换量">
				<el-input type="number" v-model="form.daynum" class="w-25">
					<template slot="append" style="color: #efefef;">*0为默认不设限制</template>
				</el-input>
			</el-form-item>
			
			<el-form-item label="开始时间">
				<el-date-picker v-model="form.dhsj" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
			</el-form-item>
			
			<el-form-item label="结束时间">
				<el-date-picker v-model="form.gqsj" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
			</el-form-item>
			
			<el-form-item label="是否上架">
				<el-radio-group v-model="form.sfsj">
					<el-radio :label="0" border>放入仓库</el-radio>
					<el-radio :label="1" border>立即上架</el-radio>
				</el-radio-group>
			</el-form-item>

		</el-form>
		<el-button type="primary" style="position: fixed;bottom: 50px;right: 50px;" @click="submit">提交</el-button>

	</div>
</template>

<script>
	import { mapState } from 'vuex'; // 引入 全局 公共数据
	export default {
		inject: ['app', 'layout'],
		data() {
			return {
				radio: 1,
				id: 0,
				cates: [],
				express: [],
				form: {
					cover:'',
					name:'',
					lx:0,
					sfsj:1,
					dhsj:'',
					daynum:0,
					desc:'',
					num:0,
					jf:0,
					gqsj:'',
				}
			}
		},
		computed: {
			...mapState({
				user: state => state.user.user,
			}),
		},
		// 生命周期
		created() {
			this.id = this.$route.params.id
			if (this.id) {
				this.layout.showLoading()
				this.axios.get('/admin/jfsc/read/' + this.id, {
					token: true
				}).then(res => {
					console.log(res)
					let data = res.data.data
					this.form = data
					this.layout.hideLoading()
				}).catch(err => {
					this.layout.hideLoading()
				})
			}
		},
		methods: {
			chooseImage() {
				this.app.chooseImage((res) => {
					this.form.cover = res[0].url
				}, 1)
			},
			//提交
			submit() {
				this.layout.showLoading()
				let url = this.id ? '/admin/jfsc/' + this.id : '/admin/jfsc'
				this.axios.post(url, this.form, {
					token: true
				}).then(res => {
					this.$message({
						type: "success",
						message: this.id ? "修改成功" : "发布成功" 
					})
					this.$router.push({
						name: "jf_jfsc"
					})
					this.layout.hideLoading()
				}).catch(err => {
					this.layout.hideLoading()
				})
			}
		},
	}
</script>

<style>
	.goods_create .el-form-item {
		margin-bottom: 15px;
	}
</style>
